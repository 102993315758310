import { useBlockNumber } from "wagmi";
import { useEffect, useState } from "react";

export function useCurrentBlock() {
  let [block, setBlock] = useState<number>();

  const { data, refetch } = useBlockNumber({
    watch: false,
  });

  useEffect(() => {
    const interval = setInterval(refetch, 10000);

    return () => clearInterval(interval);
  }, [refetch]);

  useEffect(() => {
    if (data) {
      const result = data as number;
      setBlock(result);
    }
  }, [data]);

  return block;
}
