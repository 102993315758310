import {
  AspectRatio,
  Box,
  Button,
  Center,
  Link,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  VStack,
} from "@chakra-ui/react";
import { useContractRead } from "wagmi";
import { ABI_NFT, NFT_CONTRACT_ADDRESS } from "../utils/contract";
import { useEffect, useState } from "react";
import { BigNumber } from "ethers";

interface BlackholeIdProps {
  tokenId: BigNumber | undefined;
}

const openSeaURL = process.env.REACT_APP_OPENSEA_URL!;
const nftContract = process.env.REACT_APP_NFT_CONTRACT_ADDRESS!;

export function BlackholeId(props: BlackholeIdProps) {
  const [image, setImage] = useState("");
  const [attributes, setAttributes] = useState<[Record<string, string>]>();
  const url = `${openSeaURL}/assets/ethereum/0x${nftContract}/${props.tokenId}`;
  const { data, refetch } = useContractRead({
    abi: ABI_NFT,
    address: `0x${NFT_CONTRACT_ADDRESS}`,
    functionName: "tokenURI",
    watch: false,
    enabled: false,
    args: [props.tokenId],
  });

  useEffect(() => {
    if (props.tokenId) {
      refetch();
    }
  }, [props.tokenId, refetch]);

  useEffect(() => {
    if (data) {
      const image = JSON.parse(
        Buffer.from((data as string).slice(29), "base64").toString()
      )["image"];
      setImage(image);
      setAttributes(
        JSON.parse(
          Buffer.from((data as string).slice(29), "base64").toString()
        )["attributes"]
      );
    }
  }, [data]);

  return (
    <AspectRatio ratio={1} width="100%">
      <Box
        borderTopColor="var(--chakra-colors-whiteAlpha-400)"
        borderBottomColor="var(--chakra-colors-whiteAlpha-400)"
        borderLeftColor="var(--chakra-colors-whiteAlpha-400)"
        borderRightColor="var(--chakra-colors-whiteAlpha-400)"
        borderWidth="1px"
        padding="10px"
      >
        <VStack width="100%" height="100%">
          <div
            style={{
              padding: "0px",
              width: "100%",
              height: "100%",
              backgroundImage: `url(${image})`,
              alignItems: "center",
              justifyContent: "center",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          >
            {props.tokenId !== undefined && props.tokenId.toNumber() !== 0 && (
              <Center>
                <Link isExternal href={url}>
                  See #{props.tokenId.toString()} on Opensea
                </Link>
              </Center>
            )}
          </div>
          <Popover placement="top">
            <PopoverTrigger>
              <Button fontSize={{ sm: "0.7rem", md: "1rem" }}>traits</Button>
            </PopoverTrigger>
            <PopoverContent
              backgroundColor="rgb(0,0,0,0.8)"
              borderColor="white"
              fontSize={{ sm: "0.5rem", md: "0.9rem" }}
            >
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                {" "}
                <List>
                  {attributes?.map((x) => (
                    <ListItem key={x.trait_type}>
                      <strong>{x.trait_type}</strong>: {x.value}
                    </ListItem>
                  ))}
                </List>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </VStack>
      </Box>
    </AspectRatio>
  );
}
