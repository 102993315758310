import { Heading } from "@chakra-ui/react";
import { BigNumber } from "ethers";
import { formatEther } from "ethers/lib/utils.js";

type MassProps = {
  mass: BigNumber | undefined;
};

function Mass(props: MassProps) {
  function getMass() {
    if (props.mass !== undefined)
      try {
        return formatEther(props.mass);
      } catch (e) {
        return "...";
      }

    return "...";
  }
  return (
    <div className="Mass">
      <Heading as="h1" size={{ sd: "md", md: "lg" }} textAlign="center">
        Peak Mass: 6.93 ETH,
        <br />
        flowed to hawkingradiation.eth.
      </Heading>
    </div>
  );
}

export default Mass;
