import { Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAccount, useContractRead } from "wagmi";
import { ABI_NFT, NFT_CONTRACT_ADDRESS } from "../utils/contract";
import { BigNumber } from "ethers";
import Burn from "../Burn/Burn";
import { fetchJson } from "ethers/lib/utils.js";

type MassProps = {
  mass: BigNumber | undefined;
  isConnected: boolean;
  open: (options?: any) => Promise<void>;
  refetchData: () => void;
  disabled: boolean;
};

function YourWallet(props: MassProps) {
  const { address, isDisconnected } = useAccount();
  const { data, isLoading, error, refetch } = useContractRead({
    abi: ABI_NFT,
    address: `0x${NFT_CONTRACT_ADDRESS}`,
    functionName: "lastBurner",
    watch: false,
  });

  const [lastBurner, setLastBurner] = useState<string>();
  const [lastBurnerENS, setLastBurnerENS] = useState<string>();
  const [massOwner, setMassOwner] = useState<boolean>(false);

  async function setENS(address: string) {
    fetchJson(`https://ensdata.net/${address}`);
  }

  useEffect(() => {
    if (data && !isDisconnected) {
      const setENS = async (address: string) => {
        if (address === "0x9dCEaa80E2500B519090a6d8a1FfeDfe224Ceb8D") {
          setLastBurnerENS("hawkingradiation.eth");
        } else {
          const result = await fetchJson(`https://ensdata.net/${address}`);
          try {
            const ens = result["ens"];
            if (ens.length <= 27) setLastBurnerENS(ens);
            else setLastBurnerENS("");
          } catch (e) {
            setLastBurnerENS("");
          }
        }
      };

      const address = data as string;
      setLastBurner(address);
      setENS(address);
    }
  }, [data, isDisconnected, address]);

  useEffect(() => {
    setMassOwner(lastBurner === address && address !== undefined);
  }, [lastBurner, address]);

  if (isLoading && !isDisconnected) {
    return <div className="YourWallet">...</div>;
  }

  if (error && !isDisconnected) {
    return <div className="YourWallet">error loading</div>;
  }

  return (
    <div className="YourWallet">
      <VStack>
        <Burn
          open={props.open}
          refetchData={props.refetchData}
          disabled={props.disabled}
        />
        {massOwner && (
          <Text textColor="#66ff66" fontWeight="bold">
            You own the mass.
          </Text>
        )}
        {!massOwner && lastBurnerENS !== undefined && lastBurnerENS !== "" && (
          <Text textAlign="center" textColor="#ff0000" fontWeight="bold">
            {lastBurnerENS}
            <br /> owns the mass.
          </Text>
        )}
        {!massOwner && (lastBurnerENS === undefined || lastBurnerENS === "") && (
          <Text textAlign="center" textColor="#ff0000" fontWeight="bold">
            {lastBurner?.slice(0, 4)}
            {""}..
            {lastBurner?.slice(lastBurner?.length - 2, lastBurner?.length)}
            <br /> owns the mass.
          </Text>
        )}
      </VStack>
    </div>
  );
}

export default YourWallet;
