import { ABI_NFT, NFT_CONTRACT_ADDRESS } from "../utils/contract";
import { useContractRead } from "wagmi";
import { useEffect, useState } from "react";
import { BigNumber } from "ethers";

export function usePrice() {
  let [price, setPrice] = useState<BigNumber>(BigNumber.from(0));

  const { data } = useContractRead({
    abi: ABI_NFT,
    address: `0x${NFT_CONTRACT_ADDRESS}`,
    functionName: "currentPrice",
    watch: true,
  });

  useEffect(() => {
    if (data) {
      const result = data as BigNumber;
      setPrice(result);
    }
  }, [data]);

  return price;
}
