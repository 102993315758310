import {
  VStack,
  Heading,
  Button,
  Spacer,
  Flex,
  IconButton,
  Link,
  Box,
} from "@chakra-ui/react";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount } from "wagmi";
import "./style.css";
import Countdown from "./Countdown/Countdown";
import Mass from "./Mass/Mass";
import { useRef } from "react";
import { NFT } from "./NFT/NFT";
import { EtherscanIcon, OpenseaIcon } from "./Icons";
import useData from "./Data/Data";

const openSeaURL = process.env.REACT_APP_OPENSEA_URL!;
const etherscanURL = process.env.REACT_APP_ETHERSCAN_URL!;
const nftContract = process.env.REACT_APP_NFT_CONTRACT_ADDRESS!;
const opensea = `${openSeaURL}/collection/b1ac01e`;
const etherscan = `${etherscanURL}/0x${nftContract}`;

export function App() {
  const aboutRef = useRef<HTMLHeadingElement>(null);
  const { address, isConnected } = useAccount();
  const { open } = useWeb3Modal();
  const { mass, burnt, minted, lastTimestamp, mintable, refetch } = useData();

  const executeScroll = () =>
    aboutRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });

  return (
    <Box>
      <Flex padding="20px" direction="row">
        {address !== undefined && (
          <Button width="150px">
            {address.slice(0, 5)}...{address.slice(-3)}
          </Button>
        )}
        {!isConnected && (
          <Button width="150px" onClick={() => open()}>
            CONNECT
          </Button>
        )}
        <Spacer />
        <Link isExternal href={etherscan}>
          <IconButton
            aria-label="Etherscan"
            icon={<EtherscanIcon />}
          ></IconButton>
        </Link>
        <Link isExternal href={opensea}>
          <IconButton
            style={{
              paddingTop: "4px",
            }}
            aria-label="Opensea"
            icon={<OpenseaIcon />}
          ></IconButton>
        </Link>
        <Button width="150px" onClick={executeScroll}>
          ABOUT
        </Button>
      </Flex>
      <VStack height="100%">
        <Heading as="h1" size="4xl">
          BLACK HOLE
        </Heading>

        <Countdown lastTimestamp={lastTimestamp} />
        <Mass mass={mass} />
        <NFT
          isConnected={isConnected}
          open={open}
          mass={mass}
          aboutRef={aboutRef}
          scroller={executeScroll}
          burnt={burnt}
          minted={minted}
          lastTimestamp={lastTimestamp}
          mintable={mintable}
          refetchData={refetch}
        />
      </VStack>
    </Box>
  );
}
