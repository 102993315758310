export const NFT_CONTRACT_ADDRESS = process.env.REACT_APP_NFT_CONTRACT_ADDRESS!;
export const PRICE = process.env.REACT_APP_PRICE!;

export const ABI_NFT = [
  "function burnt() public view returns (uint256)",
  "function minted() public view returns (uint256)",
  "function mass() public view returns (uint256)",
  "function render(uint256 seed) public view returns (string memory)",
  "function tokenURI(uint256 seed) public view returns (string memory)",
  "function mintable() public view returns (uint256[32] memory blocks, uint256[32] memory seeds, uint256[32] memory tokenIds)",
  "function currentPrice() public view returns (uint256)",
  "function lastTimestamp() public view returns (uint256)",
  "function lastBurner() public view returns (address)",
  "function mint(uint256 blockNumber) public payable",
  "function burn(uint256 tokenId) public",
];
