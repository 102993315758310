import {
  VStack,
  Heading,
  Spacer,
  Flex,
  IconButton,
  Link,
  Box,
  SimpleGrid,
  Center,
  Button,
  Text,
} from "@chakra-ui/react";
import "./style.css";
import { EtherscanIcon, OpenseaIcon } from "./Icons";
import { BigNumber } from "ethers";
import { BlackholeId } from "./BlackholeId/BlackholeId";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

const openSeaURL = process.env.REACT_APP_OPENSEA_URL!;
const etherscanURL = process.env.REACT_APP_ETHERSCAN_URL!;
const nftContract = process.env.REACT_APP_NFT_CONTRACT_ADDRESS!;
const opensea = `${openSeaURL}/collection/b1ac01e`;
const etherscan = `${etherscanURL}/0x${nftContract}`;

export function Show() {
  const [searchParams] = useSearchParams();
  const [tokenIds, setTokenIds] = useState<BigNumber[]>();
  const [name, setName] = useState<string>("");
  const [columnsDesktop, setColumnsDesktop] = useState<number>(2);

  useEffect(() => {
    const ids = searchParams.get("ids");
    if (ids) {
      setTokenIds(ids.split(",").map((id) => BigNumber.from(id)));
      if (ids.length === 1) {
        setColumnsDesktop(1);
      } else {
        setColumnsDesktop(2);
      }
    }
    const authorParam = searchParams.get("author");
    if (authorParam) setName(authorParam);
    const nameParam = searchParams.get("name");
    if (nameParam) setName(nameParam);

    if (!authorParam && !nameParam) setName("");
  }, [searchParams]);

  return (
    <Box>
      <Flex padding="20px" direction="row">
        <Spacer />
        <Link isExternal href={etherscan}>
          <IconButton
            aria-label="Etherscan"
            icon={<EtherscanIcon />}
          ></IconButton>
        </Link>
        <Link isExternal href={opensea}>
          <IconButton
            style={{
              paddingTop: "4px",
            }}
            aria-label="Opensea"
            icon={<OpenseaIcon />}
          ></IconButton>
        </Link>
        <Button width="150px">
          <Link href="/">ABOUT</Link>
        </Button>
        <Spacer />
      </Flex>
      <Center>
        <Text
          alignContent="center"
          textAlign="center"
          fontWeight="bold"
          fontSize={{ base: "2rem", sm: "2rem", md: "5rem", lg: "3rem" }}
        >
          BLACK HOLE - EXHIBITION
        </Text>
        <br />
      </Center>
      <Center>
        <Text
          alignContent="center"
          textAlign="center"
          fontWeight="bold"
          fontSize={{ base: "1.5rem", md: "3rem", lg: "3rem" }}
        >
          <strong>{name}</strong>
        </Text>
      </Center>
      <Center>
        <Text
          alignContent="center"
          textAlign="center"
          fontWeight="bold"
          fontSize={{ base: "1rem", md: "1.5rem", lg: "3rem" }}
        >
          read more on the{" "}
          <Link href="/">
            <strong>BLACK HOLE</strong>
          </Link>
        </Text>
      </Center>
      <br />
      <br />
      <br />
      <Center>
        <SimpleGrid columns={[1, 1, columnsDesktop]} spacing="40px" width="80%">
          {tokenIds &&
            tokenIds.map((id) => {
              return <BlackholeId tokenId={id} />;
            })}
        </SimpleGrid>
      </Center>
    </Box>
  );
}
