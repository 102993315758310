import { Icon } from "@chakra-ui/react";

type BadgeProps = {
  status: "on" | "off" | "wait";
};

export function Badge(props: BadgeProps) {
  return (
    <Icon
      className="badge"
      viewBox="0 0 200 200"
      color={{ on: "green", off: "red", wait: "yellow" }[props.status]}
      boxSize={4}
    >
      <path
        fill="currentColor"
        d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
      />
    </Icon>
  );
}
