import {
  Button,
  Center,
  Input,
  InputGroup,
  InputLeftAddon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  Toast,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { parseEther } from "ethers/lib/utils.js";
import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { ABI_NFT, NFT_CONTRACT_ADDRESS } from "../utils/contract";
import { Badge } from "../NFT/Badge";
import { useEffect, useState } from "react";

type BurnProps = {
  open: (options?: any) => Promise<void>;
  refetchData: () => void;
  disabled: boolean;
};

function Burn(props: BurnProps) {
  const toast = useToast();
  const { address } = useAccount();
  const { onToggle } = useDisclosure();
  const [tokenId, setTokenId] = useState("");
  const handleChange = (event: any) => setTokenId(event.target.value);

  const { config, isError, refetch } = usePrepareContractWrite({
    address: `0x${NFT_CONTRACT_ADDRESS}`,
    abi: ABI_NFT,
    functionName: "burn",
    args: [tokenId],
    enabled: tokenId !== "",
  });

  const { data, write } = useContractWrite(config);
  const waitForTransaction = useWaitForTransaction({
    hash: data?.hash,
    confirmations: 1,
    onSuccess: () => {
      props.refetchData();
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "There was an error while burning.",
        isClosable: true,
        status: "error",
        containerStyle: {
          backgroundColor: "black",
          borderColor: "red",
        },
      });
      props.refetchData();
    },
  });

  useEffect(() => {
    if (waitForTransaction.isFetched) {
      refetch();
    }
  }, [waitForTransaction.isFetched, refetch]);

  async function burn() {
    write?.();
  }

  function status() {
    if (waitForTransaction.isLoading) {
      return "wait";
    }

    return "off";
  }

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          onClick={() => (address !== undefined ? onToggle() : props.open())}
          disabled={address === undefined || props.disabled}
          width="150px"
        >
          BURN
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          width="200px"
          backgroundColor="black"
          borderColor="white"
        >
          <PopoverArrow />
          <PopoverHeader>
            <Center>
              Your Token ID
              <br />
              (check opensea)
            </Center>
          </PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody>
            <InputGroup>
              <InputLeftAddon children="#" />
              <Input
                placeholder="token id"
                value={tokenId}
                onChange={handleChange}
              />
            </InputGroup>
            <Center>
              <VStack>
                <Button
                  margin="10px"
                  rightIcon={<Badge status={status()} />}
                  onClick={() =>
                    address !== undefined ? burn() : props.open()
                  }
                  disabled={tokenId === undefined || isError}
                >
                  {waitForTransaction.isLoading ? "..." : "BURN"}
                </Button>
                {waitForTransaction.isLoading && !isError && <Text></Text>}
                {isError && <Text>invalid id</Text>}
              </VStack>
            </Center>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

export default Burn;
