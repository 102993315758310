import { BigNumber } from "ethers";
import { useEffect, useState } from "react";
import { useContractReads } from "wagmi";
import { ABI_NFT, NFT_CONTRACT_ADDRESS } from "../utils/contract";

export type Mintable = {
  tokenIds: BigNumber[];
  blocks: BigNumber[];
  seeds: BigNumber[];
};

function useData() {
  const [mass, setMass] = useState(BigNumber.from(0));
  const [burnt, setBurnt] = useState<number>();
  const [minted, setMinted] = useState<number>();
  const [lastTimestamp, setLastTimestamp] = useState<number>();
  const [mintable, setMintable] = useState<Mintable>();

  const { data, refetch } = useContractReads({
    contracts: [
      {
        address: `0x${NFT_CONTRACT_ADDRESS}`,
        abi: ABI_NFT,
        functionName: "mass",
      },
      {
        address: `0x${NFT_CONTRACT_ADDRESS}`,
        abi: ABI_NFT,
        functionName: "burnt",
      },
      {
        address: `0x${NFT_CONTRACT_ADDRESS}`,
        abi: ABI_NFT,
        functionName: "minted",
      },
      {
        address: `0x${NFT_CONTRACT_ADDRESS}`,
        abi: ABI_NFT,
        functionName: "lastTimestamp",
      },
      {
        address: `0x${NFT_CONTRACT_ADDRESS}`,
        abi: ABI_NFT,
        functionName: "mintable",
      },
    ],
    scopeKey: "data",
    watch: false,
  });

  useEffect(() => {
    if (data) {
      const result = data as any[];
      if ((result[0] as BigNumber) !== undefined)
        setMass(result[0] as BigNumber);

      if ((result[1] as BigNumber) !== undefined)
        setBurnt((result[1] as BigNumber).toNumber());

      if ((result[2] as BigNumber) !== undefined)
        setMinted((result[2] as BigNumber).toNumber());

      if ((result[3] as BigNumber) !== undefined)
        setLastTimestamp((result[3] as BigNumber).toNumber());

      setMintable(result[4] as Mintable);
    }
  }, [data]);

  return { mass, burnt, minted, lastTimestamp, mintable, refetch };
}

export default useData;
