import {
  VStack,
  Heading,
  Spacer,
  Flex,
  IconButton,
  Link,
  Box,
  SimpleGrid,
  Center,
  Button,
} from "@chakra-ui/react";
import "./style.css";
import { EtherscanIcon, OpenseaIcon } from "./Icons";
import { Blackhole } from "./Blackhole/Blackhole";
import { BigNumber } from "ethers";

const openSeaURL = process.env.REACT_APP_OPENSEA_URL!;
const etherscanURL = process.env.REACT_APP_ETHERSCAN_URL!;
const nftContract = process.env.REACT_APP_NFT_CONTRACT_ADDRESS!;
const opensea = `${openSeaURL}/collection/b1ac01e`;
const etherscan = `${etherscanURL}/0x${nftContract}`;

export function Some() {
  return (
    <Box>
      <Flex padding="20px" direction="row">
        <Spacer />
        <Link isExternal href={etherscan}>
          <IconButton
            aria-label="Etherscan"
            icon={<EtherscanIcon />}
          ></IconButton>
        </Link>
        <Link isExternal href={opensea}>
          <IconButton
            style={{
              paddingTop: "4px",
            }}
            aria-label="Opensea"
            icon={<OpenseaIcon />}
          ></IconButton>
        </Link>
        <Button width="150px">
          <Link href="/">ABOUT</Link>
        </Button>
      </Flex>
      <Center>
        <Heading as="h1" size={{ sd: "3xl", md: "4xl" }}>
          BLACK HOLES
        </Heading>
        <br />
      </Center>
      <Center>
        <Heading as="h3" size={{ sd: "lg", md: "xl" }}>
          a hand curated selection
        </Heading>
      </Center>
      <Center>
        <Heading as="h1" size={{ sd: "md", md: "lg" }}>
          from the <Link href="/">BLACKHOLE</Link>
        </Heading>
      </Center>
      <br />
      <br />
      <br />
      <Center>
        <SimpleGrid minChildWidth="320px" spacing="40px" width="80%">
          <Blackhole
            seed={
              "34656562179697648983445141100215485676456766901516411722105586573055999120436"
            }
            tokenId={BigNumber.from("160")}
          />
          <Blackhole
            seed={
              "7129313856341417629734755702186611993014268866375594642695837659902793466539"
            }
            tokenId={BigNumber.from("158")}
          />
          <Blackhole
            seed={
              "111896707542995093821783437013353839456141376211420178719340072319545765134432"
            }
            tokenId={BigNumber.from("155")}
          />
          <Blackhole
            seed={
              "55211629833877506516991895164043973791188900918804898464513920793243456874804"
            }
            tokenId={BigNumber.from("135")}
          />
          <Blackhole
            seed={
              "97222793744304703088169317003218433766698070701401107115476374107049635467596"
            }
            tokenId={BigNumber.from("126")}
          />
          <Blackhole
            seed={
              "67568276370531807184676969890595181855050962766929852206805064430247889723003"
            }
            tokenId={BigNumber.from("82")}
          />
          <Blackhole
            seed={
              "51353295126927892912469141920314224323327380077515397301793517687326596012205"
            }
            tokenId={BigNumber.from("58")}
          />
          <Blackhole
            seed={
              "41430591359310959619371541047173251821165760550669278829677282696735416218660"
            }
            tokenId={BigNumber.from("42")}
          />
          <Blackhole
            seed={
              "11802018804281016597011800042638892726013013619368380610311274706562744975031"
            }
            tokenId={BigNumber.from("9")}
          />
        </SimpleGrid>
      </Center>
    </Box>
  );
}
