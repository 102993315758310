import { VStack } from "@chakra-ui/react";

function AgeBox() {
  return (
    <VStack>
      <span>Age</span>
      <span>113d</span>
    </VStack>
  );
}

export default AgeBox;
