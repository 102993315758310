import { Flex, Heading, Spacer, VStack } from "@chakra-ui/react";
import "./style.css";
import { ABI_NFT, NFT_CONTRACT_ADDRESS } from "../utils/contract";
import { useContractRead } from "wagmi";
import { ForwardedRef, useEffect, useState } from "react";
import { BigNumber } from "ethers";
import { usePrice } from "../Price/Price";
import { About } from "../About/About";
import BurntBox from "../Burnt/BurntBox";
import SupplyBox from "../Supply/SupplyBox";
import MintedBox from "../Minted/MintedBox";
import { ContentDesktop } from "./ContentDesktop";
import { ContentMobile } from "./ContentMobile";
import { Mintable } from "../Data/Data";
import AgeBox from "../AgeBox/AgeBox";

type NFTProps = {
  isConnected: boolean;
  mass: BigNumber | undefined;
  aboutRef: ForwardedRef<HTMLHeadingElement>;
  open: (options?: any) => Promise<void>;
  scroller: (arg: any) => void;
  burnt: number | undefined;
  minted: number | undefined;
  lastTimestamp: number | undefined;
  mintable: Mintable | undefined;
  refetchData: () => void;
};

export function NFT(props: NFTProps) {
  const [timeLeft, setTimeLeft] = useState(0);
  const price = usePrice();
  const [blocks, setBlocks] = useState<number[]>([]);
  const [seeds, setSeeds] = useState<string[]>([]);
  const [tokenIds, setTokenIds] = useState<Record<number, BigNumber>>();
  const [currentImageIndex, setCurrentImageIndex] = useState(-1);
  const [currentBlock, setCurrentBlock] = useState<number>();
  const [currentSeed, setCurrentSeed] = useState<string>();
  const [currentTokenId, setCurrentTokenId] = useState<BigNumber>(
    BigNumber.from(0)
  );

  const { data, refetch } = useContractRead({
    abi: ABI_NFT,
    address: `0x${NFT_CONTRACT_ADDRESS}`,
    functionName: "mintable",
    watch: false,
  });

  useEffect(() => {
    const interval = setInterval(refetch, 10000);

    return () => clearInterval(interval);
  }, [refetch]);

  useEffect(() => {
    if (props.lastTimestamp) {
      const timestamp = props.lastTimestamp + 60 * 60 * 42;
      if (timestamp > Date.now() / 1000)
        setTimeLeft(timestamp - Date.now() / 1000);
      else {
        setTimeLeft(0);
      }
    }
  }, [props]);

  useEffect(() => {
    if (data) {
      const result = data as Mintable;
      const blocksStrings = result.blocks.map((x) => x.toNumber());
      let newBlocks = blocksStrings.filter(
        (x) => !blocks.includes(x) && x !== 0
      );
      const seedsStrings = result.seeds.map((x) => x.toHexString());
      const newSeeds = seedsStrings.filter(
        (x) => !seeds.includes(x) && x !== "0x00"
      );

      setBlocks(blocks.concat(newBlocks.slice().reverse()));
      setSeeds(seeds.concat(newSeeds.slice().reverse()));

      if (tokenIds) {
        Object.keys(tokenIds).forEach((key) =>
          setTokenIds((prevTokenIds) => ({
            ...prevTokenIds,
            [key]: BigNumber.from(0),
          }))
        );
      }

      for (let i = 0; i < blocksStrings.length; i++) {
        setTokenIds((prevTokenIds) => ({
          ...prevTokenIds,
          [blocksStrings[i]]: result.tokenIds[i],
        }));
      }

      if (currentImageIndex === -1) {
        setCurrentImageIndex(result.blocks.length - 1);
      }
    }
  }, [data]);

  useEffect(() => {
    if (tokenIds && blocks) {
      setCurrentTokenId(tokenIds[blocks[currentImageIndex]]);
      setCurrentBlock(blocks[currentImageIndex]);
      setCurrentSeed(seeds[currentImageIndex]);
    }
  }, [tokenIds, blocks, seeds, currentImageIndex]);

  return (
    <VStack height="70vh" width="100%" maxWidth="1200px">
      <ContentDesktop
        currentImageIndex={currentImageIndex}
        currentBlock={currentBlock}
        currentSeed={currentSeed}
        currentTokenId={currentTokenId}
        blocks={blocks}
        setCurrentImageIndex={setCurrentImageIndex}
        price={price}
        isConnected={props.isConnected}
        mass={props.mass}
        open={props.open}
        display={{
          base: "none",
          md: "flex",
          lg: "flex",
          xl: "flex",
          "2xl": "flex",
          sm: "none",
        }}
        scroller={props.scroller}
        refetchData={props.refetchData}
        timeLeft={timeLeft}
      />

      <ContentMobile
        currentImageIndex={currentImageIndex}
        currentBlock={currentBlock}
        currentSeed={currentSeed}
        currentTokenId={currentTokenId}
        blocks={blocks}
        setCurrentImageIndex={setCurrentImageIndex}
        price={price}
        isConnected={props.isConnected}
        mass={props.mass}
        open={props.open}
        display={{
          base: "block",
          md: "none",
          lg: "none",
          xl: "none",
          "2xl": "none",
          sm: "block",
        }}
        scroller={props.scroller}
        refetchData={props.refetchData}
        timeLeft={timeLeft}
      />

      <br />
      <br />
      <Heading>Stats</Heading>
      <Flex width="100%" maxW="500px" padding="20px" direction="row">
        <MintedBox minted={props.minted} />
        <Spacer />
        <BurntBox burnt={props.burnt} />
        <Spacer />
        <SupplyBox burnt={props.burnt} minted={props.minted} />
        <Spacer />
        <AgeBox />
      </Flex>
      <br />
      <About aboutRef={props.aboutRef} />
    </VStack>
  );
}
