import { Heading } from "@chakra-ui/react";
import { BigNumber } from "ethers";
import { useEffect, useState } from "react";
import { useContractRead } from "wagmi";
import { useCountdown } from "../hooks/useCountdown";
import { ABI_NFT, NFT_CONTRACT_ADDRESS } from "../utils/contract";

type CountdownProps = {
  lastTimestamp: number | undefined;
};

function Countdown(props: CountdownProps) {
  return (
    <div className="Countdown">
      <Heading as="h3" size="1s">
        <div>Dissolved on 15th Nov. 2023 at 4:27:00</div>
      </Heading>
    </div>
  );
}

export default Countdown;
