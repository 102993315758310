import {
  ChakraProvider,
  VStack,
  Center,
  Heading,
  Button,
  Spacer,
  Flex,
  IconButton,
  Link,
} from "@chakra-ui/react";
import { EthereumClient, w3mConnectors } from "@web3modal/ethereum";
import { useWeb3Modal, Web3Modal } from "@web3modal/react";
import {
  configureChains,
  createClient,
  useAccount,
  useNetwork,
  WagmiConfig,
} from "wagmi";
import { mainnet, goerli, localhost, sepolia } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { extendTheme } from "@chakra-ui/react";
import "./style.css";
import Countdown from "./Countdown/Countdown";
import Mass from "./Mass/Mass";
import { useRef, useState } from "react";
import { BigNumber } from "ethers";
import { NFT } from "./NFT/NFT";
import NetworkStatus from "./NetworkStatus/NetworkStatus";
import { EtherscanIcon, OpenseaIcon } from "./Icons";
import useData from "./Data/Data";
import { App } from "./App";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import { Some } from "./Some";
import { Show } from "./Show";

const openSeaURL = process.env.REACT_APP_OPENSEA_URL!;
const etherscanURL = process.env.REACT_APP_ETHERSCAN_URL!;
const nftContract = process.env.REACT_APP_NFT_CONTRACT_ADDRESS!;
const opensea = `${openSeaURL}/collection/b1ac01e`;
const etherscan = `${etherscanURL}/0x${nftContract}`;

// Version 1: Using objects
const theme = extendTheme({
  fonts: {
    heading: `"JuraHeading", "Arial", "serif"`,
    body: `"Jura", "Arial", "serif"`,
  },
  fontSizes: {
    sm: "1.4rem",
    md: "1.2rem",
    lg: "1.3rem",
  },
  breakpoints: {
    sm: "500px",
    md: "1200px",
  },

  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: "black",
        color: "white",
      },
    },
  },
  components: {
    Link: {
      baseStyle: {
        textDecoration: "underline",
      },
    },
    Button: {
      baseStyle: {
        bgColor: "black",
        borderTopColor: "black",
        borderRightColor: "black",
        borderBottomColor: "var(--chakra-colors-whiteAlpha-800)",
        borderLeftColor: "black",
        borderWidth: "1px",
        borderRadius: 3,
        _hover: {
          //bgColor: "var(--chakra-colors-whiteAlpha-500)",
          borderBottomColor: "var(--chakra-colors-whiteAlpha-800)",
          borderLeftColor: "black",
          borderTopColor: "black",
          borderRightColor: "black",
        },
        _active: {
          bgColor: "var(--chakra-colors-whiteAlpha-500)",
        },
      },
      variants: {
        // Make a variant, we'll call it `base` here and leave it empty
        base: {},
        secondary: {
          //...define other variants
        },
      },
      defaultProps: {
        // Then here we set the base variant as the default
        variant: "base",
      },
    },
  },
});

const getChains = (network: string | undefined) => {
  switch (network) {
    case "localhost":
      return localhost;
    case "ethereum":
      return mainnet;
    case "goerli":
      return goerli;
    case "sepolia":
      return sepolia;
    default:
      return localhost;
  }
};
const chains = [getChains(process.env.REACT_APP_NETWORK)];

const projectId = process.env.REACT_APP_PROJECT_ID!;
const alchemyProjectId = process.env.REACT_APP_ALCHEMY_PROJECT_ID!;
const infuraProjectId = process.env.REACT_APP_INFURA_PROJECT_ID!;

// Wagmi client
const { provider } = configureChains(chains, [
  //w3mProvider({ projectId }),
  alchemyProvider({ apiKey: alchemyProjectId }),
  alchemyProvider({ apiKey: infuraProjectId }),
]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  provider,
});

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, chains);

const MOBILE_WALLETS = [
  {
    id: "trust",
    name: "Trust Wallet",
    links: {
      native: "trust://",
      universal: "https://link.trustwallet.com",
    },
  },
  {
    id: "rainbow",
    name: "Rainbow",
    links: {
      native: "rainbow://",
      universal: "https://rainbow.me",
    },
  },
  {
    id: "zerion",
    name: "Zerion",
    links: {
      native: "zerion://",
      universal: "https://wallet.zerion.io",
    },
  },
  {
    id: "tokenary",
    name: "Tokenary",
    links: {
      native: "tokenary://",
      universal: "https://tokenary.io",
    },
  },
];
// Custom Linking Desktop Wallets
const DESKTOP_WALLETS = [
  {
    id: "ledger",
    name: "Ledger",
    links: {
      native: "ledgerlive://",
      universal: "https://www.ledger.com",
    },
  },
  {
    id: "zerion",
    name: "Zerion",
    links: {
      native: "zerion://",
      universal: "https://wallet.zerion.io",
    },
  },
  {
    id: "tokenary",
    name: "Tokenary",
    links: {
      native: "tokenary://",
      universal: "https://tokenary.io",
    },
  },
  {
    id: "oreid",
    name: "OREID",
    links: {
      native: "",
      universal: "https://www.oreid.io/",
    },
  },
];

interface MainProps {
  render: string;
}

export function Main(props: MainProps) {
  const { isConnected } = useAccount();
  const { chain } = useNetwork();

  return (
    <ChakraProvider theme={theme}>
      <WagmiConfig client={wagmiClient}>
        <NetworkStatus
          didConnect={isConnected}
          network={chain?.name.toLowerCase()}
        />

        <HashRouter>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/selection" element={<Some />} />
            <Route path="/show" element={<Show />} />
          </Routes>
        </HashRouter>
        <Web3Modal
          ethereumClient={ethereumClient}
          // Custom Linking Mobile Wallets
          themeMode="dark"
          projectId={projectId}
          mobileWallets={MOBILE_WALLETS}
          desktopWallets={DESKTOP_WALLETS}
        />
      </WagmiConfig>
    </ChakraProvider>
  );
}
