import { VStack } from "@chakra-ui/react";

type SupplyProps = {
  burnt: number | undefined;
  minted: number | undefined;
};

function SupplyBox(props: SupplyProps) {
  return (
    <VStack>
      <span>Supply</span>
      <span>
        {props.minted && props.burnt ? props.minted - props.burnt : "-"}
      </span>
    </VStack>
  );
}

export default SupplyBox;
