import { VStack } from "@chakra-ui/react";

type BurntProps = {
  burnt: number | undefined;
};

function BurntBox(props: BurntProps) {
  return (
    <VStack>
      <span>Burnt</span>
      <span>{props.burnt ? props.burnt.toString() : "-"}</span>
    </VStack>
  );
}

export default BurntBox;
