import {
  AspectRatio,
  Box,
  Heading,
  Image,
  Link,
  List,
  ListIcon,
  ListItem,
  VStack,
} from "@chakra-ui/react";
import { ForwardedRef } from "react";
import { FaChevronRight } from "react-icons/fa";

type AboutProps = {
  aboutRef: ForwardedRef<HTMLHeadingElement>;
};

export function About(props: AboutProps) {
  return (
    <VStack maxWidth="750px" width="100%" padding="20px">
      <Heading ref={props.aboutRef} as="h1">
        About
      </Heading>
      <br />
      <Heading fontSize={{ md: "lg" }} width="100%" textAlign="left">
        The Rules
      </Heading>
      <Box fontSize={{ md: "lg" }} textAlign="left">
        These are the rules of the cosmic ballet of these generative NFTs:
        <List>
          <ListItem>
            <ListIcon as={FaChevronRight} />
            Each act of creation, a mint, stirs the ether, lifting the price up
            by 0.00020190410 ETH.
          </ListItem>
          <ListItem>
            <ListIcon as={FaChevronRight} />
            Each return to the void, a burn, cools the price down by
            0.00020190410 ETH.
          </ListItem>
          <ListItem>
            <ListIcon as={FaChevronRight} />
            Ether, whether from mints or royalties, is drawn inexorably into the
            Blackhole, amassing an ever-increasing mass.
          </ListItem>
        </List>
        <br />
        Should 42 hours pass without any mint, nor burns, the Blackhole, unable
        to withstand the gravitational pull of inactivity, dissolves.
        <br />
        <br />
        After this celestial event, creation and destruction come to an end:{" "}
        <strong>
          no more mints, no more burns. The supply will freeze and the
          collection will be what it became.
        </strong>
        .
        <br />
        <br />
        The mass of the Black Hole (Ether) is destined to the last collector who
        burnt their token.
        <br />
        <br />
        Owners of tokens #1 to #10 are entitled to 1% of the mass, withdrawable
        at the time of their choosing <strong>before</strong> dissolution,{" "}
        <strong>once</strong>.
        <br />
        <br />
        The creator, 5%.
      </Box>
      <br />
      <br />
      <Heading fontSize={{ md: "lg" }} width="100%" textAlign="left">
        The Concept
      </Heading>
      <Box fontSize={{ md: "lg" }} textAlign="left">
        On the surface, the Black Hole is an on-chain (in-chain to be precise),
        generative art project. The NFT itself has its unique peculiarities (see
        "The NFT" section), but I also wanted to make the collection "behave"
        like a Black Hole.
        <br />
        <br />
        The rules coded in the Smart Contract, are made to incentivize minting
        and burning, making the collection <strong>
          expand and contract
        </strong>{" "}
        and causing a continuous accumultion of ether in the contract.
        <br />
        <br />
        Unless all activity stops, the Black Hole will keep absorbing ether,
        swelling over time, potentially never stopping, until either all Ether
        or all humanity is gone.
        <br />
        <br />
        The collection will freeze its supply and become determined only once
        all activity stops. <i>Its existence begins with its end</i>.
        <br />
        <br />
        Will the network succumb to the Black Hole? Humans have the choice.
      </Box>
      <br />
      <br />
      <Heading fontSize={{ md: "lg" }} width="100%" textAlign="left">
        The NFT
      </Heading>
      <Box fontSize={{ md: "lg" }} textAlign="left">
        Rooted in algorithmic design, each black hole offers an infinite, unique
        visual experience that transcends the static nature of traditional NFTs,
        echoing the perpetual motion and fluidity of the universe itself.
        <br />
        <br />
        Each blackhole is:
        <List>
          <ListItem>
            <ListIcon as={FaChevronRight} />
            On-Chain: everything that you see, is generated by the Smart
            Contract.
          </ListItem>
          <ListItem>
            <ListIcon as={FaChevronRight} />
            Generative: seeded by the on-chain chaos of the block hashes.
          </ListItem>
          <ListItem>
            <ListIcon as={FaChevronRight} />
            Perpetual: a unique and unending digital artwork that perpetually
            animates, unfolding a ceaseless array of distinctive patterns over
            time.
          </ListItem>
        </List>
        <br />
        <br />
        Some black holes may seem chaotic for a long while before they start
        revealing their inner, harmonius, geometric patterns.
        <br />
        Maybe some of them will loop only after hours, days or years of
        watching.
        <br />
        This black hole showed, to the creator surprise, a Flower of Life
        pattern, after more than 5 minutes of swirling (cut to a few seconds in
        this sample):
        <br />
        <AspectRatio ratio={1}>
          <Image src="flower_square.gif" objectFit="cover" />
        </AspectRatio>
        A new NFT becomes mintable every 8 blocks. After 256 blocks, an NFT with
        no owner will cease to be mintable.
      </Box>
      <br />
      <br />

      <Heading fontSize={{ md: "lg" }} width="100%" textAlign="left">
        About the Artist
      </Heading>
      <Box fontSize={{ md: "lg" }} textAlign="left">
        My name is Miragenesi, former Inner Space. I create space ambient music
        and on-chain NFTs, first one being the{" "}
        <Link href="https://opensea.io/collection/moon-in-motion" isExternal>
          Moon in Motion
        </Link>
        .<br />
        <br />
        You can follow me on{" "}
        <Link href="https://twitter.com/miragenesi" isExternal>
          Twitter
        </Link>
        ,{" "}
        <Link href="https://warpcast.com/innerspace" isExternal>
          Warpcast
        </Link>
        ,{" "}
        <Link href="https://music.miragenesi.art" isExternal>
          Bandcamp
        </Link>{" "}
        or check my work on{" "}
        <Link href="https://linktr.ee/miragenesi" isExternal>
          LinkTree
        </Link>
        .
      </Box>
    </VStack>
  );
}
