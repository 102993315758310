import { useState, useEffect } from "react";
import "./NetworkStatus.css";

type NetworkStatusProps = {
  network: string | undefined;
  didConnect: boolean;
};

function NetworkStatus(props: NetworkStatusProps) {
  const NETWORK = process.env.REACT_APP_NETWORK;

  function isError() {
    return NETWORK !== props.network && props.network !== undefined;
  }

  return (
    <div className="NetworkStatus" hidden={!isError()}>
      {isError() && (
        <div className="error-banner">
          <span className="error-text">
            You are connected to {props.network} whilst you should be connected
            to {NETWORK}
          </span>
        </div>
      )}
    </div>
  );
}

export default NetworkStatus;
