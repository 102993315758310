import {
  Center,
  Flex,
  HStack,
  IconButton,
  Link,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import "./style.css";
import { Blackhole } from "../Blackhole/Blackhole";
import { BigNumber } from "ethers";
import { ArrowLeftIcon, ArrowRightIcon } from "../Icons";
import Mint from "../Mint/Mint";
import { formatEther } from "ethers/lib/utils.js";
import YourWallet from "../YourWallet/YourWallet";

type ContentProps = {
  currentImageIndex: number;
  currentBlock: number | undefined;
  currentSeed: string | undefined;
  currentTokenId: BigNumber;
  blocks: number[];
  setCurrentImageIndex: (index: number) => void;
  price: BigNumber;
  isConnected: boolean;
  mass: BigNumber | undefined;
  open: (options?: any) => Promise<void>;
  display: Record<"sm" | "md" | "lg" | "xl" | "2xl" | "base", string>;
  scroller: (arg: any) => void;
  refetchData: () => void;
  timeLeft: number;
};

export function ContentMobile(props: ContentProps) {
  const executeScroll = () =>
    props.scroller({
      behavior: "smooth",
      block: "start",
    });

  return (
    <VStack width="100%" marginTop="50px" display={props.display}>
      <VStack width="100%">
        <Flex width="80%">
          <VStack>
            <Mint
              block={props.currentBlock}
              seed={props.currentSeed}
              minted={
                props.currentTokenId && props.currentTokenId.toNumber() !== 0
              }
              isConnected={props.isConnected}
              open={props.open}
              price={props.price}
              refetchData={props.refetchData}
              disabled={props.timeLeft <= 0}
            />
            <Text textAlign="center" fontWeight="bold">
              {formatEther(props.price)} ETH.
            </Text>
          </VStack>
          <Spacer />
          <YourWallet
            mass={props.mass}
            isConnected={props.isConnected}
            open={props.open}
            refetchData={props.refetchData}
            disabled={props.timeLeft <= 0}
          />
        </Flex>
        <HStack width="100%">
          <IconButton
            variant="unstyled"
            borderColor="black"
            icon={<ArrowLeftIcon />}
            aria-label={"Left"}
            isDisabled={props.currentImageIndex === 0}
            onClick={() =>
              props.setCurrentImageIndex(props.currentImageIndex - 1)
            }
          />
          <Blackhole seed={props.currentSeed} tokenId={props.currentTokenId} />
          <IconButton
            variant="unstyled"
            icon={<ArrowRightIcon />}
            borderColor="black"
            aria-label={"Right"}
            isDisabled={props.currentImageIndex === props.blocks.length - 1}
            onClick={() =>
              props.setCurrentImageIndex(props.currentImageIndex + 1)
            }
          />
        </HStack>
      </VStack>
      <Center>
        <Text alignContent="bottom" textAlign="center" width="90%">
          the Black Hole generates
          <br /> on-chain perpetual NFTs
          <br />
          every 8th block.
          <br />
          <br />
          mint, price goes up.
          <br />
          burn, price goes down.
          <br />
          <br />
          all ETH falls into its mass.
          <br />
          after 42 hours with no burns and no mints,
          <br />
          the Black Hole dissolves.
          <br />
          <br />
          last address that burnt an NFT, owns the mass.
          <br />
          <br />
          a cosmic dance of expantion and contraction.
          <br />
          <strong>a collection whose existence will begin with its end.</strong>
          <br />
          <br />
          <Link onClick={executeScroll}>tokens #1 to #10 are special</Link>.
        </Text>
      </Center>
    </VStack>
  );
}
