import { Button, useToast } from "@chakra-ui/react";
import { useContractWrite, useWaitForTransaction } from "wagmi";
import { ABI_NFT, NFT_CONTRACT_ADDRESS } from "../utils/contract";
import { Badge } from "../NFT/Badge";
import { useCurrentBlock } from "../Block/Block";
import { BigNumber } from "ethers";

type MintProps = {
  block: number | undefined;
  seed?: string | undefined;
  minted: boolean;
  isConnected: boolean;
  open: (options?: any) => Promise<void>;
  price: BigNumber;
  refetchData: () => void;
  disabled: boolean;
};

function Mint(props: MintProps) {
  const toast = useToast();
  const currentBlock = useCurrentBlock();

  const { data, write } = useContractWrite({
    address: `0x${NFT_CONTRACT_ADDRESS}`,
    abi: ABI_NFT,
    functionName: "mint",
    overrides: {
      value: props.price,
    },
    args: [props.block],
    mode: "recklesslyUnprepared",
  });

  const waitForTransaction = useWaitForTransaction({
    hash: data?.hash,
    confirmations: 1,
    onSuccess: () => {
      props.refetchData();
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "There was an error while minting.",
        isClosable: true,
        status: "error",
        containerStyle: {
          backgroundColor: "black",
          borderColor: "red",
        },
      });
      props.refetchData();
    },
  });

  function validBlock() {
    return (
      props.block !== undefined &&
      currentBlock !== undefined &&
      props.block > currentBlock - 256
    );
  }

  async function mint() {
    write?.();
  }

  function status() {
    if (props.minted || !validBlock()) {
      return "off";
    }
    if (!props.minted && waitForTransaction.isLoading) {
      return "wait";
    }

    return "on";
  }

  return (
    <Button
      rightIcon={<Badge status={status()} />}
      width="150px"
      onClick={() => (props.isConnected ? mint() : props.open())}
      disabled={props.minted || !validBlock() || props.disabled}
    >
      {waitForTransaction.isLoading ? "..." : "MINT"}
    </Button>
  );
}

export default Mint;
